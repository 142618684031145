import { Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"

const FAQ = () => {
  const faq = [
    {
      q: "How does it work?",
      a:
        "Once you've purchased a list you will get a link to a google data sheet as well as a download link in your e-mail that you can use to download the file. Note that it can take up to 24hours for the link to generate.",
    },
    {
      q: "Do you work with the influencers on the list?",
      a:
        "We don't have any relationship with the influencers on theinfluencerlist. They are chosen based on their content and metrics alone.",
    },
    {
      q: "Why do you only release a list every 3 months?",
      a:
        "By comparing the list in a 3 month interval we get a more consistent tracking of their growth forecast.",
    },
    {
      q: "Why do some columns have the word (SAMPLE) added?",
      a:
        "We have the sample tag to note that the data in that column isn't for the entire lifespan of the account, but for a specific timespan. This is to ensure that the data we show is as much an accurate representation of the influencers current data as possible. And not their historical data.",
    },
    {
      q: "Why does the latest cost more than the previous version?",
      a:
        "In addition to updating all the previous influencers we also add ~20% more influencers each new version.",
    },
    {
      q: "How do i contact influencers when I'm done searching?",
      a:
        "We recommend you reach out to them personally on DM's on the respective platform introducing yourself, to give it a personal touch.",
    },
    {
      q: "I want a refund",
      a: "Contact us at hey@theinfluencerlist.co",
    },
    {
      q: "I got scammed!",
      a:
        "We are sorry to hear that. While we don't have a preexisting releationship with the influencers in our list fraudalent behaviour is not something we tolerate on our list. We can't do anything about your situation but we can stop it from happening to someone else. Please contact us at email@mail.com with details of who it was.",
    },
    {
      q: "I don't want my profile listed on theinfluencerlist",
      a:
        "No worries! Contact us with your profile details and we will remove you.",
    },
    {
      q: "I have a question that isn't answered here",
      a:
        "We are happy to answer all and any questions you have. Reach out to us on hey@theinfluencerlist.co",
    },
  ]

  return (
    <Layout>
      <section className="w-full h-36 bg-grey pt-12 pb-24">
        {/* FAQ */}
        <h1 className="text-center text-3xl uppercase track-wider">
          Frequently Asked Question
        </h1>
        <div>
          <ul className="">
            {faq.map(el => (
              <li className="mx-10 my-8 divide-y-2 divide-gray-400">
                <h3 className="text-xl m-1">{el.q}</h3>
                <p className="italic m-0 pt-2 mb-4 pl-4">{el.a}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default FAQ
